import { Children } from 'react';
import type { CommonHTMLAttributes } from '@frontend/types';
import { useStyles } from '../../../use-styles';

export type TabBarProps = CommonHTMLAttributes & {
  activeTab: string;
  children: Array<JSX.Element | boolean | null>;
};

export const TabBar = ({ activeTab, children, ...rest }: TabBarProps) => {
  const childArr = Children.toArray(children).filter(Boolean) as JSX.Element[];
  const activeIndex = childArr.findIndex((child) => child.props.id === activeTab);
  const tabWidth = 100 / childArr.length;
  const styles = useStyles('TabFilter', 'tabBar', { activeIndex, tabWidth });

  return (
    <div css={styles} {...rest}>
      {children}
    </div>
  );
};
