import { MultiStepModal } from './atoms/modal/modal.component';
import { Step } from './atoms/step/step';
import { MultiStepStepper } from './atoms/stepper/stepper';
import { MultiStepStepperItem } from './atoms/stepper/stepper-item';
import { MultiStepRoot } from './multi-step.component';

export * from './multi-step.context';
export * from './multi-step.hooks';
export * from './multi-step.provider';
export * from './multi-step.types';
export * from './atoms/step/step-close-button';
export * from './atoms/step/useStepContext';
export type * from './multi-step.component';
export type * from './atoms/modal/modal.component';
export type * from './atoms/stepper/stepper';
export type * from './atoms/stepper/stepper-item';
export type * from './atoms/step/step';
export type * from './atoms/step/step-body';
export type * from './atoms/step/step-footer';
export type * from './atoms/step/step-header';

const MultiStepNamespace = {
  Root: MultiStepRoot,
  Step,
  Stepper: MultiStepStepper,
  StepperItem: MultiStepStepperItem,
  Modal: MultiStepModal,
};

export { MultiStepNamespace as MultiStep };
