import React from 'react';
import { useMultiStepContext } from '../../multi-step.context';
import { StepProps } from './step';
import { useStepData } from './step.hooks';
import { StepContext } from './useStepContext';

export function StepProvider({
  id,
  isValid,
  isComplete,
  children,
  showCancelAction: showCancelActionControl,
}: StepProps) {
  const { stepData, isActive, stepIndex, nextStep, prevStep, setStepData, setValid, setComplete } = useStepData({
    id,
    isValid,
    isComplete,
  });

  const { showCancelAction: showCancelActionInternal } = useMultiStepContext();

  const showCancelAction = React.useMemo(() => {
    return typeof showCancelActionControl === 'boolean' ? showCancelActionControl : showCancelActionInternal;
  }, [showCancelActionControl, showCancelActionInternal]);

  /**
   * Sync step complete / valid state from props
   */
  React.useEffect(() => {
    if (!stepData) return;

    if (typeof isValid === 'boolean' && stepData.isValid !== isValid) {
      setValid(isValid);
    }

    if (typeof isComplete === 'boolean' && stepData.isComplete !== isComplete) {
      setComplete(isComplete);
    }
  }, [isValid, isComplete, stepData]);

  if (!stepData) return null;

  return (
    <StepContext.Provider
      value={{
        stepData,
        isActive,
        stepIndex,
        nextStep,
        prevStep,
        showCancelAction,
        setStepData,
        setValid,
        setComplete,
      }}
    >
      {children}
    </StepContext.Provider>
  );
}
