import { classNames } from '@frontend/string';
import { multiStepStyles } from '../../multi-step.styles';

export type StepBodyProps = { className?: string; children: React.ReactNode };

export function StepBody({ className, children }: StepBodyProps) {
  return (
    <div className={classNames('step__body', className)} css={multiStepStyles.stepBody}>
      {children}
    </div>
  );
}
