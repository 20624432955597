import type { CommonHTMLAttributes } from '@frontend/types';
import { useListboxContext, type ListboxChildren } from './listbox.provider';
import { StyledListboxList } from './styled-listbox-list';

type Props = CommonHTMLAttributes & {
  children: ListboxChildren;
};

export const ListboxList = ({ children, ...rest }: Props) => {
  const { listboxProps } = useListboxContext();
  return (
    <StyledListboxList {...listboxProps} {...rest}>
      {children}
    </StyledListboxList>
  );
};
