import { useMultiStepContext } from '../../multi-step.context';
import { StepData } from '../../multi-step.types';
import { useStepData } from './step.hooks';

export const useStepFooterHandlerProps = (step: string) => {
  const { goPrevStep, goNextStep, onComplete, onCancel, setActiveStep, getStepData } = useMultiStepContext();

  const { id, isValid, isComplete } = getStepData(step) || {};

  const { stepData, nextStep, prevStep, setValid, setComplete } = useStepData({
    id: id || step,
    isValid,
    isComplete,
  });

  const handlerProps = {
    goNextStep,
    goPrevStep,
    onCompleteAll: onComplete,
    onCancelAll: onCancel,
    setActiveStep,
    setComplete,
    setValid,
    stepData: stepData || ({} as StepData),
    nextStep,
    prevStep,
  };

  return handlerProps;
};
