import React from 'react';
import { classNames } from '@frontend/string';
import { multiStepStyles } from '../../multi-step.styles';
import { StepCallbackProps } from '../../multi-step.types';
import { StepBody, StepBodyProps } from './step-body';
import { StepFooterProps, StepFooter } from './step-footer';
import { StepHeaderProps, StepHeader } from './step-header';
import { StepProvider } from './step.provider';
import { useStepFooterHandlerProps } from './use-step-footer-handler-props';
import { useStepContext } from './useStepContext';

export type StepProps = React.PropsWithChildren<{
  className?: string;

  /**
   * Unique identifier for the step
   */
  id: string;

  /**
   * Use to add an additional step without having it
   * show up in stepper.
   */
  parentId?: string;

  /**
   * Label for the step. Used by default in the header's title, and stepper item's label.
   */
  label: string;

  /**
   * By default true.
   * When validation occurs, use to prevent moving to the next step.
   */
  isValid?: boolean;

  /**
   * Set by default when a valid step > footer > primary button is clicked.
   */
  isComplete?: boolean;

  /**
   * Show or hide the "cancel" button in the footer.
   * If present, overrides global context's value.
   */
  showCancelAction?: boolean;

  /**
   * Any/all props passed to the step's header.
   */
  header?: StepHeaderProps;

  /**
   * Any/all props passed to the step's footer.
   */
  footer?: StepFooterProps;

  /**
   * Default components used by the step.
   * Pass in a custom variation as needed. Or use the default component,
   * but override some of the props.
   */
  components?: Partial<{
    Header: (props: StepHeaderProps) => React.ReactNode;
    Footer: (props: StepFooterProps & { handlerProps?: StepCallbackProps }) => React.ReactNode;
    Body: (props: StepBodyProps) => React.ReactNode;
  }>;
}>;

function Step({ className, children, components = {}, footer, header, ...props }: StepProps) {
  const { Header = StepHeader, Footer = StepFooter, Body = StepBody } = components;

  return (
    <StepProvider {...props}>
      <StepVisibilityController className={className}>
        <div className='multi-step__header-wrapper'>
          <Header {...header} />
        </div>
        <Body>{children}</Body>
        <StepFooterWrapper step={props.id} footer={Footer} footerProps={footer || {}} />
      </StepVisibilityController>
    </StepProvider>
  );
}

function StepFooterWrapper({
  step,
  footer: Footer,
  footerProps,
}: {
  step: string;
  footer: (props: StepFooterProps & { handlerProps: StepCallbackProps }) => React.ReactNode;
  footerProps: StepFooterProps;
}) {
  const handlerProps = useStepFooterHandlerProps(step);

  return (
    <div className='multi-step__footer-wrapper'>
      <Footer {...footerProps} handlerProps={handlerProps} />
    </div>
  );
}

Step.displayName = 'Step';

function StepVisibilityController({ className, children }: { className?: string; children: React.ReactNode }) {
  const { isActive } = useStepContext();
  if (!isActive) {
    return null;
  }
  return (
    <div className={classNames('step', className)} css={multiStepStyles.step}>
      {children}
    </div>
  );
}

type StepNamespace = React.FC<StepProps> & {
  Body: typeof StepBody;
  Header: typeof StepHeader;
  Footer: typeof StepFooter;
};

const StepNamespace = Step as StepNamespace;

StepNamespace.Body = StepBody;
StepNamespace.Header = StepHeader;
StepNamespace.Footer = StepFooter;

export { StepNamespace as Step };
