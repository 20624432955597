import React from 'react';

const FULL_VALUE = 'full' as const;

export function useCustomContainerBreakpoints<BPS extends Record<string, number>, T extends React.ElementType = 'div'>(
  breakpointsMap: BPS,
  enabled = true
) {
  const containerRef = React.useRef<React.ElementRef<T>>(null);
  const [size, setSize] = React.useState<keyof BPS | typeof FULL_VALUE>(FULL_VALUE);
  const currentWidth = React.useRef(0);

  const sortedBreakpoints = React.useMemo(() => {
    return Object.entries(breakpointsMap).sort((a, b) => {
      return b[1] - a[1];
    }) as [keyof BPS, number][];
  }, [breakpointsMap]);

  const calcSize = (currentWidth: number) => {
    let size: keyof BPS = FULL_VALUE;
    if (currentWidth > 0) {
      for (let i = 0; i < sortedBreakpoints.length; i++) {
        const [key, value] = sortedBreakpoints[i];
        if (currentWidth <= value) {
          size = key;
        } else {
          break;
        }
      }
    }
    return size;
  };

  React.useEffect(() => {
    if (!containerRef.current || !enabled) {
      return;
    }

    const observer = new ResizeObserver(([entry]) => {
      const elementWidth = entry.borderBoxSize?.[0]?.inlineSize ?? entry.contentRect.width;

      currentWidth.current = elementWidth;

      const newSize = calcSize(elementWidth);
      if (newSize !== size) {
        setSize(newSize);
      }
    });

    observer.observe(containerRef.current as unknown as HTMLDivElement);

    return () => {
      observer.disconnect();
    };
  }, [enabled, size]);

  React.useLayoutEffect(() => {
    if (!size) {
      setSize(calcSize(currentWidth.current));
    }
  });

  const state = React.useMemo(() => {
    const isGtThan = (bp: keyof BPS | typeof FULL_VALUE) => {
      if (bp === FULL_VALUE) {
        /**
         * Can never be bigger than full
         */
        return false;
      }
      return sortedBreakpoints.findIndex(([key]) => key === bp) > sortedBreakpoints.findIndex(([key]) => key === size);
    };

    const isLtThan = (bp: keyof BPS | typeof FULL_VALUE) => {
      if (bp === FULL_VALUE) {
        return currentWidth.current > sortedBreakpoints[0][1];
      }
      const value =
        sortedBreakpoints.findIndex(([key]) => key === bp) < sortedBreakpoints.findIndex(([key]) => key === size);

      return value;
    };

    const isSize = (bp: keyof BPS | typeof FULL_VALUE) => {
      return size === bp;
    };

    return {
      size,
      isGtThan,
      isLtThan,
      isSize,
    };
  }, [size]);

  return [state, containerRef] as const;
}

export type CustomBreakpoint<BPS extends string> = ReturnType<
  typeof useCustomContainerBreakpoints<Record<BPS, number>>
>[0];
