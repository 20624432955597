import { useMultiStepContext } from '../multi-step.context';

type MakeObj<O extends object> = {
  [K in keyof O]: string;
};
export const useScopedTrackingIds = <O extends object>(map: O) => {
  const { trackingId } = useMultiStepContext();

  return Object.fromEntries(Object.entries(map).map(([key, value]) => [key, `${trackingId}--${value}`])) as MakeObj<O>;
};
