import { Interpolation, Theme } from '@emotion/react';
import { classNames } from '@frontend/string';
import { Button, ButtonProps } from '../../../button';
import { useMultiStepContext } from '../../multi-step.context';
import { multiStepStyles } from '../../multi-step.styles';
import { MultiStepActionButton, MultiStepActionButtonProps, StepCallbackProps } from '../../multi-step.types';
import { useScopedTrackingIds } from '../../utils/use-scoped-tracking-ids';
import { useStepFooterHandlerProps } from './use-step-footer-handler-props';
import { useStepContext } from './useStepContext';

export type StepFooterProps = {
  className?: string;
  css?: Interpolation<Theme>;
  showBackButton?: boolean;
  helpText?: string | (() => React.ReactNode);

  onClickPrimary?: (props: StepCallbackProps) => void;
  onClickSecondary?: (props: StepCallbackProps) => void;
  onClickBack?: (props: StepCallbackProps) => void;

  primary?: string | MultiStepActionButton | MultiStepActionButtonProps | null;
  secondary?: string | MultiStepActionButton | MultiStepActionButtonProps | null;
  back?: string | MultiStepActionButton | MultiStepActionButtonProps | null;
};

export function StepFooter({
  className,
  primary,
  secondary,
  back,
  showBackButton: showBackButtonForced,
  onClickPrimary: onClickPrimaryDefault,
  onClickSecondary: onClickSecondaryDefault,
  onClickBack: onClickBackDefault,
  helpText,
}: StepFooterProps) {
  const { goPrevStep, goNextStep, onComplete } = useMultiStepContext();
  const { nextStep, prevStep, stepData, setComplete } = useStepContext();

  const { isValid, isComplete } = stepData;

  const handlerProps = useStepFooterHandlerProps(stepData.id);

  const canProceed = isValid;

  const onClickBack = () => {
    if (typeof onClickBackDefault === 'function') {
      return onClickBackDefault(handlerProps);
    }

    goPrevStep();
  };

  const showBackButton = typeof showBackButtonForced === 'boolean' ? showBackButtonForced : !!prevStep;

  const onClickSecondary = () => {
    if (typeof onClickSecondaryDefault !== 'function') {
      return console.warn('Secondary button does not have a default action. Please supply `onClickSecondary` prop.');
    }

    return onClickSecondaryDefault(handlerProps);
  };

  const onClickPrimary = () => {
    if (typeof onClickPrimaryDefault === 'function') {
      return onClickPrimaryDefault(handlerProps);
    }

    if (!canProceed) {
      return;
    }

    if (!isComplete) {
      setComplete(true);
    }

    if (nextStep) {
      goNextStep();
    } else {
      onComplete();
    }
  };

  const { primaryTrackingId, secondaryTrackingId, backTrackingId } = useScopedTrackingIds({
    primaryTrackingId: 'primary-btn',
    secondaryTrackingId: 'secondary-btn',
    backTrackingId: 'back-btn',
  });

  return (
    <div className={classNames('step__footer', className)} css={multiStepStyles.stepFooter}>
      {showBackButton && (
        <div className='step__footer-back' css={multiStepStyles.stepBackButton}>
          <ActionButtonWrapper action={back} variant='tertiary' onClick={onClickBack} trackingId={backTrackingId}>
            Back
          </ActionButtonWrapper>
        </div>
      )}
      {helpText && <div css={multiStepStyles.helpText}>{typeof helpText === 'function' ? helpText() : helpText}</div>}

      <ActionButtonWrapper
        action={secondary}
        variant='secondary'
        onClick={onClickSecondary}
        trackingId={secondaryTrackingId}
      />

      <ActionButtonWrapper
        action={primary}
        disabled={!canProceed}
        onClick={onClickPrimary}
        trackingId={primaryTrackingId}
      >
        {nextStep ? 'Continue' : 'Finish'}
      </ActionButtonWrapper>
    </div>
  );
}

type ActionButtonWrapperProps = ButtonProps & {
  action?: string | MultiStepActionButton | MultiStepActionButtonProps | null;
};

function ActionButtonWrapper({ action, variant, onClick, children, disabled, ...props }: ActionButtonWrapperProps) {
  if (typeof action === 'function') {
    return action({ onClick, variant, children, disabled, ...props });
  }

  if (action === null) {
    return null;
  }

  const {
    disabled: actionDisabled = undefined,
    onClick: actionOnClick = null,
    children: actionChildren = null,
    variant: actionVariant = undefined,
    ...actionProps
  } = typeof action === 'object' ? action : {};

  const content = typeof action === 'string' ? action : actionChildren || children;

  if (!content) {
    return null;
  }

  const computedDisabled = typeof actionDisabled === 'boolean' ? actionDisabled : disabled;

  const computedVariant = actionVariant || variant;

  const handleClickEvents = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (typeof actionOnClick === 'function') {
      actionOnClick(event);
    }
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <div>
      <Button
        {...actionProps}
        {...props}
        disabled={computedDisabled}
        variant={computedVariant}
        onClick={handleClickEvents}
      >
        {content}
      </Button>
    </div>
  );
}
