import React from 'react';
import type { CommonHTMLButtonAttributes } from '@frontend/types';
import { InfoRoundIconSmall, IconProps } from '../../icon';
import { NakedButton } from '../../naked';
import { useStyles } from '../../use-styles';
import type { Placement } from '../hint/get-position';
import { useTooltip } from '../tooltip';

interface InfoType extends CommonHTMLButtonAttributes {
  children: React.ReactNode;
  hasInteractiveContent?: boolean;
  placement?: Placement;
  color?: IconProps['color'];
  trigger?: 'hover' | 'click';
  hoverDelay?: number;
  iconSize?: number;
}

export const Info = ({
  children,
  hasInteractiveContent,
  placement,
  color,
  trigger = 'click',
  hoverDelay,
  iconSize = 16,
  ...rest
}: InfoType) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement, trigger, hoverDelay });
  const style = useStyles('Info');
  return (
    <>
      <NakedButton type='button' css={style} {...rest} {...triggerProps}>
        <InfoRoundIconSmall size={iconSize} color={color} />
      </NakedButton>
      <Tooltip hasInteractiveContent={hasInteractiveContent} {...tooltipProps}>
        {children}
      </Tooltip>
    </>
  );
};
