import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { onlyText } from 'react-children-utilities';
import { theme } from '@frontend/theme';

export const NotificationBadge = ({
  children,
  count,
  truncateCount = false,
  ...rest
}: {
  children: ReactNode;
  className?: string;
  count?: ReactNode;
  truncateCount?: boolean;
}) => {
  const exactCount = +onlyText(children);
  const displayCount = truncateCount ? (exactCount > 99 ? '99+' : exactCount) : onlyText(children);

  return (
    <motion.span
      layout='size'
      css={[
        css`
          align-items: center;
          background-color: ${theme.colors.critical50};
          border-radius: 10px;
          color: ${theme.colors.white};
          display: flex;
          font-size: ${theme.fontSize(12)};
          font-weight: ${theme.font.weight.bold};
          height: ${theme.spacing(2.25)};
          justify-content: center;
          overflow: hidden;
          padding: ${theme.spacing(0.25, 0.75)};
          text-align: left;
        `,
        truncateCount &&
          css`
            .display-count {
              display: block;
            }
            .exact-count {
              display: none;
            }
            :hover {
              .display-count {
                display: none;
              }
              .exact-count {
                display: block;
              }
            }
          `,
      ]}
      {...rest}
    >
      <span className='display-count'>{displayCount}</span>
      {truncateCount && <span className='exact-count'>{exactCount}</span>}
    </motion.span>
  );
};
