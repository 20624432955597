import React from 'react';
import { useMultiStepContext } from '../../multi-step.context';

export type UseStepDataProps = {
  id: string;
  isValid?: boolean;
  isComplete?: boolean;
};
export function useStepData({ id, isValid, isComplete }: UseStepDataProps) {
  const { setStepData, getStepData, activeStep, steps } = useMultiStepContext();

  const stepData = React.useMemo(() => {
    return getStepData(id);
  }, [getStepData, id]);

  const setValid = React.useCallback(
    (bool: boolean) => {
      if (!stepData) return;
      setStepData({ ...stepData, isValid: typeof isValid === 'boolean' ? isValid : bool });
    },
    [stepData, isValid]
  );

  const setComplete = React.useCallback(
    (bool: boolean) => {
      if (!stepData) return;
      setStepData({ ...stepData, isComplete: typeof isComplete === 'boolean' ? isComplete : bool });
    },
    [stepData, isComplete]
  );

  const stepIndex = React.useMemo(() => {
    if (!stepData) return -1;
    return steps.findIndex((s) => s.id === stepData.id);
  }, [steps, stepData]);

  const isActive = React.useMemo(() => {
    if (!stepData) return false;
    return activeStep === stepData.id;
  }, [activeStep, stepData]);

  const nextStep = React.useMemo(() => {
    return steps[stepIndex + 1]?.id || null;
  }, [stepIndex, steps]);

  const prevStep = React.useMemo(() => {
    return steps[stepIndex - 1]?.id || null;
  }, [stepIndex, steps]);

  return {
    stepData,
    isActive,
    stepIndex,
    nextStep,
    prevStep,
    setStepData,
    setValid,
    setComplete,
  };
}
