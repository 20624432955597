import React from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { classNames } from '@frontend/string';
import { useMultiStepContext } from '../../multi-step.context';
import { multiStepStyles } from '../../multi-step.styles';
import { StepCallbackProps } from '../../multi-step.types';
import { useScopedTrackingIds } from '../../utils/use-scoped-tracking-ids';
import { StepButtonProps, StepCloseButton } from './step-close-button';
import { useStepContext } from './useStepContext';

export type StepHeaderProps = {
  className?: string;
  css?: Interpolation<Theme>;
  title?: string | (() => React.ReactNode);
  showCancelAction?: boolean;
  onClickCancel?: (props: StepCallbackProps) => void;
  components?: Partial<{
    Action?: (props: StepButtonProps) => React.ReactNode;
  }>;
};
export function StepHeader({
  className,
  title,
  showCancelAction: showCancelActionControl,
  onClickCancel,
  components = {},
}: StepHeaderProps) {
  const { onCancel, goNextStep, goPrevStep, onComplete, setActiveStep } = useMultiStepContext();
  const {
    stepData,
    showCancelAction: showCancelActionInternal,
    nextStep,
    prevStep,
    setComplete,
    setValid,
  } = useStepContext();

  const { Action = StepCloseButton } = components;

  const handlerProps = {
    setComplete,
    setValid,
    goNextStep,
    goPrevStep,
    onCompleteAll: onComplete,
    onCancelAll: onCancel,
    setActiveStep,
    stepData,
    nextStep,
    prevStep,
  };

  const handleCancelClick = () => {
    if (typeof onClickCancel === 'function') {
      return onClickCancel(handlerProps);
    }

    if (typeof onCancel === 'function') {
      return onCancel();
    }

    console.warn(
      'Unhandled Step > Header > Cancel action. MultiStep should be provided `onCancel` or Step.Header should be given onClickCancel'
    );
  };

  const showCancelAction = React.useMemo(() => {
    return typeof showCancelActionControl === 'boolean' ? showCancelActionControl : showCancelActionInternal;
  }, [showCancelActionControl, showCancelActionInternal]);

  const renderedTitle = typeof title === 'function' ? title() : title;

  const { headerCloseTrackingId } = useScopedTrackingIds({
    headerCloseTrackingId: 'header-close',
  });

  return (
    <div className={classNames('step__header', className)} css={multiStepStyles.stepHeader}>
      <div className='step__header-title'>{typeof renderedTitle !== 'undefined' ? renderedTitle : stepData.label}</div>
      {showCancelAction && (
        <div className='step___header-action'>
          <Action trackingId={headerCloseTrackingId} onClick={handleCancelClick} />
        </div>
      )}
    </div>
  );
}
