import { IconName } from '@frontend/icons';
import { Button, ButtonSize, ButtonVariants } from '../../../button';
import { StepData } from '../../multi-step.types';
import { useStepContext } from './useStepContext';

export type StepButtonProps = {
  onClick: (props: { step: StepData }) => void;
  size?: ButtonSize;
  variant?: ButtonVariants;
  iconName?: IconName;
  trackingId?: string;
};
export function StepCloseButton({
  onClick,
  size = 'large',
  variant = 'secondary',
  iconName = 'x',
  trackingId,
}: StepButtonProps) {
  const { stepData } = useStepContext();
  return (
    <Button
      trackingId={trackingId}
      variant={variant}
      size={size}
      iconName={iconName}
      onClick={() => onClick({ step: stepData })}
    >
      {null}
    </Button>
  );
}
