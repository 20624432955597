import React from 'react';
import { css } from '@emotion/react';
import { config } from 'react-spring';
import { useSlideTransition } from '../../../hooks/animations';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { XIcon } from '../../../icon/icons/x';
import { IconButton } from '../../icon-button';
import { ModalProvider, ModalProviderProps } from '../provider/modal.provider';
import { ModalControlModalProps } from '../provider/use-modal-control';
import { FullscreenModalActions } from './fullscreen-modal-actions.component';
import { FullscreenModalBody } from './fullscreen-modal-body.component';
import { FullScreenModalContainer } from './fullscreen-modal-container.component';
import { FullscreenModalHeader } from './fullscreen-modal-header.component';

type Props = ModalControlModalProps &
  Pick<ModalProviderProps, 'mountTarget' | 'prepend'> & {
    children: React.ReactNode;
    className?: string;
  };

const fullscreenModalClassname = 'wv-fullscreen-modal';

export const FullscreenModal = ({ children, onClose, mountTarget, prepend, show, ...rest }: Props) => {
  const transitions = useSlideTransition({
    active: show,
    direction: 'up',
    distance: '100%',
    config: { ...config.wobbly, friction: 24 },
  });
  const { zIndex } = useThemeValues();
  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <ModalProvider
              key={key}
              className={fullscreenModalClassname}
              onClose={onClose}
              timeout={500}
              mountTarget={mountTarget}
              prepend={prepend}
              show={show}
            >
              <FullScreenModalContainer style={props} {...rest}>
                {children}
                <IconButton
                  css={css`
                    -webkit-app-region: none;
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    z-index: ${zIndex.low};
                  `}
                  onClick={onClose}
                  label='Close modal'
                  name='Close modal'
                >
                  <XIcon />
                </IconButton>
              </FullScreenModalContainer>
            </ModalProvider>
          )
      )}
    </>
  );
};

FullscreenModal.Header = FullscreenModalHeader;
FullscreenModal.Body = FullscreenModalBody;
FullscreenModal.Actions = FullscreenModalActions;
