import React from 'react';
import { css } from '@emotion/react';
import type { ColumnInstance, TableInstance } from 'react-table';
import { ButtonBar, PrimaryButton, SecondaryButton } from '../../';
import { useForm } from '../../';
import { Tray } from '../../';
import { useThemeValues } from '../../../hooks';
import { styles } from '../../../styles';
import { useStyles } from '../../../use-styles';
import { FormConfig } from '../../forms/hooks';
import { ModalControlModalProps } from '../../modal';
import { TableData } from '../table-data-type';
import { FilterSidebarHeader } from './atoms';
import { Filter } from './molecules';
import { convertObjectToArray } from './utils';

export const TableFilters = <T extends TableData>({
  tableInstance,
  ...modalProps
}: ModalControlModalProps & {
  tableInstance: TableInstance<T>;
}) => {
  const theme = useThemeValues();
  const buttonBarStyles = useStyles('TableCommonStyles', 'tableFilterStyles', { type: 'filterSidebarButtons' });
  const { allColumns, setAllFilters, gotoPage, tableTrackingIds } = tableInstance;
  const filterableColumns = React.useMemo(() => allColumns.filter((col) => !!col.filterConfig), [allColumns]);

  const renderFilter = (): FormConfig => {
    return filterableColumns.reduce((acc, current) => {
      const { filterConfig } = current;
      const { label, ...rest } = filterConfig!;
      return { ...acc, [current.id]: { ...rest } };
    }, {});
  };

  const { formProps, getFieldProps, seedValues, reset } = useForm({
    fields: renderFilter(),
    onSubmit: (values) => {
      gotoPage(0);
      setAllFilters(convertObjectToArray(values));
    },
  });

  return (
    <Tray
      /*The "+1" is included because filters should appear above the modal when the table is rendered on a modal. */
      zIndex={theme.zIndex.modals + 1}
      width='small'
      {...modalProps}
      css={{ padding: 0 }}
    >
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <FilterSidebarHeader closeFilterSidebar={modalProps.onClose} />
        <form
          {...formProps}
          id='table-filters'
          css={[
            css`
              flex: 1;
              overflow-y: auto;
            `,
            styles.smallScrollbar(theme),
          ]}
        >
          {filterableColumns.map((props) => (
            <FilterItem
              key={props.id}
              {...props}
              seedValues={seedValues}
              getFieldProps={getFieldProps}
              gotoPage={gotoPage}
            />
          ))}
        </form>
        <ButtonBar css={buttonBarStyles}>
          <SecondaryButton
            trackingId={tableTrackingIds.clientFilterActions?.('clear-all')}
            onClick={() => {
              reset();
              setAllFilters([]);
            }}
          >
            Clear All Filters
          </SecondaryButton>
          <PrimaryButton
            trackingId={tableTrackingIds.clientFilterActions?.('apply')}
            form='table-filters'
            type='submit'
            onClick={() => modalProps.onClose()}
          >
            Apply Filters
          </PrimaryButton>
        </ButtonBar>
      </div>
    </Tray>
  );
};

const FilterItem = <T extends object>({
  preFilteredRows,
  id,
  render,
  setFilter,
  filterConfig,
  filterValue,
  seedValues,
  getFieldProps,
  gotoPage,
}: ColumnInstance<T> &
  Pick<ReturnType<typeof useForm>, 'seedValues' | 'getFieldProps'> & {
    gotoPage: (updater: ((pageIndex: number) => number) | number) => void;
  }) => {
  const options = React.useMemo(() => {
    const uniqueOptions = new Set<any>(preFilteredRows.map((row) => row.values[id]));
    return [...uniqueOptions.values()];
  }, [id, preFilteredRows]);

  return (
    <Filter
      key={id}
      render={render}
      id={id}
      setFilter={setFilter}
      filterConfig={filterConfig}
      filterValue={filterValue}
      seedValues={seedValues}
      getFieldProps={getFieldProps}
      options={options}
      gotoPage={gotoPage}
    />
  );
};
