export const FILE_TYPES = {
  audio: 'audio/*',
  video: 'video/*',
  image: 'image/*',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  csv: 'text/csv',
  tsv: 'text/tab-separated-values',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
} as const;

const AUDIO_TYPE = FILE_TYPES.audio;

const VIDEO_TYPE = FILE_TYPES.video;

const IMAGE_TYPE = [FILE_TYPES.image, FILE_TYPES.pdf].join(',');

const DOCUMENT_TYPE = [FILE_TYPES.pdf, FILE_TYPES.doc, FILE_TYPES.docx].join(',');

const RECORD_TYPE = [FILE_TYPES.csv].join(',');

const ANY_TYPE = [IMAGE_TYPE, VIDEO_TYPE, DOCUMENT_TYPE, RECORD_TYPE, AUDIO_TYPE].join(',');

export const fileTypeMap = {
  file: DOCUMENT_TYPE,
  image: IMAGE_TYPE,
  video: VIDEO_TYPE,
  audio: AUDIO_TYPE,
  any: ANY_TYPE,
  png: FILE_TYPES.png,
  jpg: FILE_TYPES.jpg,
  jpeg: FILE_TYPES.jpeg,
  csv: FILE_TYPES.csv,
  tsv: FILE_TYPES.tsv,
  xls: FILE_TYPES.xls,
  xlsx: FILE_TYPES.xlsx,
};
